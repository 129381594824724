/* cabin-700 - latin */
@font-face {
  font-family: "Cabin";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url("/fonts/cabin-v14-latin-700.eot"); /* IE9 Compat Modes */
  src: local("Cabin Bold"), local("Cabin-Bold"),
    url("/fonts/cabin-v14-latin-700.eot?#iefix") format("embedded-opentype"),
    /* IE6-IE8 */ url("/fonts/cabin-v14-latin-700.woff2") format("woff2"),
    /* Super Modern Browsers */ url("/fonts/cabin-v14-latin-700.woff")
      format("woff"),
    /* Modern Browsers */ url("/fonts/cabin-v14-latin-700.ttf")
      format("truetype"),
    /* Safari, Android, iOS */ url("/fonts/cabin-v14-latin-700.svg#Cabin")
      format("svg"); /* Legacy iOS */
}

/* libre-franklin-regular - latin */
@font-face {
  font-family: "Libre Franklin";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url("/fonts/libre-franklin-v4-latin-regular.eot"); /* IE9 Compat Modes */
  src: local("Libre Franklin"), local("LibreFranklin-Regular"),
    url("/fonts/libre-franklin-v4-latin-regular.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-IE8 */ url("/fonts/libre-franklin-v4-latin-regular.woff2")
      format("woff2"),
    /* Super Modern Browsers */
      url("/fonts/libre-franklin-v4-latin-regular.woff") format("woff"),
    /* Modern Browsers */ url("/fonts/libre-franklin-v4-latin-regular.ttf")
      format("truetype"),
    /* Safari, Android, iOS */
      url("/fonts/libre-franklin-v4-latin-regular.svg#LibreFranklin")
      format("svg"); /* Legacy iOS */
}

/* libre-franklin-600 - latin */
@font-face {
  font-family: "Libre Franklin";
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url("/fonts/libre-franklin-v4-latin-600.eot"); /* IE9 Compat Modes */
  src: local("Libre Franklin SemiBold"), local("LibreFranklin-SemiBold"),
    url("/fonts/libre-franklin-v4-latin-600.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-IE8 */ url("/fonts/libre-franklin-v4-latin-600.woff2")
      format("woff2"),
    /* Super Modern Browsers */ url("/fonts/libre-franklin-v4-latin-600.woff")
      format("woff"),
    /* Modern Browsers */ url("/fonts/libre-franklin-v4-latin-600.ttf")
      format("truetype"),
    /* Safari, Android, iOS */
      url("/fonts/libre-franklin-v4-latin-600.svg#LibreFranklin") format("svg"); /* Legacy iOS */
}
