$sizeBase: 16px;

@function scaleUnit($multiple) {
  @return $sizeBase * $multiple;
}

@mixin margin($sides, $multiple) {
  @each $side in $sides {
    @if $side=="top" {
      margin-top: $sizeBase * $multiple;
    }

    @else if $side=="left" {
      margin-left: $sizeBase * $multiple;
    }

    @else if $side=="right" {
      margin-right: $sizeBase * $multiple;
    }

    @else if $side=="bottom" {
      margin-bottom: $sizeBase * $multiple;
    }
  }
}

@mixin padding($sides, $multiple) {
  @each $side in $sides {
    @if $side=="top" {
      padding-top: $sizeBase * $multiple;
    }

    @else if $side=="left" {
      padding-left: $sizeBase * $multiple;
    }

    @else if $side=="right" {
      padding-right: $sizeBase * $multiple;
    }

    @else if $side=="bottom" {
      padding-bottom: $sizeBase * $multiple;
    }
  }
}

$desktopBodyColumnWidth: 700px;
$desktopNavColumnWidth: 304px;

@mixin essayBodyColumnLayout {
  margin: 0 auto;

  @include respond-to(non-handhelds) {
    box-sizing: content-box;
    width: calc(100% - #{$desktopNavColumnWidth} - #{scaleUnit(5)});
    max-width: $desktopBodyColumnWidth;
    position: relative;
    left: -($desktopNavColumnWidth + scaleUnit(1)) / 2.0;
    @include padding(left, 2);
    @include padding(right, 2);
  }

  @include respond-to(handhelds) {
    @include pagePadding;
  }
}
