$gray00: #FBFBFF;
$gray10: #E1E6EA;
$gray20: #C7CFD6;
$gray30: #A7AEB5;
$gray40: #93999E;
$gray50: #797D82;
$gray60: #64646B;
$gray70: #3F464D;
$gray80: #282E33;
$gray90: #1D2024;

$key00: #FCFAFF;
$key10: #F5EBFF;
$key20: #DABAFF;
$key30: #BE8FFF;
$key40: #A15EFF;
$key50: #8B3AFC;
$key60: #6E2BCC;
$key70: #490F99;
$key80: #2E0A63;
$key90: #23133D;

$primaryLight: $key10;
$primaryKey: $key50;
$primaryDark: $key70;
$primaryExtraDark: $key80;

@mixin whenDark() {
  @at-root #{selector-append(".Dark", &)} {
    @content;
  }
}  
