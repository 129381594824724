@import "./styles/layout.scss";
@import "./styles/animation.scss";
@import "./styles/colors.scss";
@import "./styles/fonts.scss";

// DEBUG
$showBaselineGrid: false;
// Base font size and ratio
$base: 18px;
$mobileBase: 15px;
$lineHeight: 34px;
$mobileLineHeight: 29px;
$ratio: 1.5; // Perfect Fifth
$borderRadius: 8px;
$bodyFont: "Libre Baskerville", "Baskerville", "Big Caslon", "Cambria", serif;
$headingFont: "Libre Franklin",
sans-serif;
$UIFont: "Libre Franklin",
sans-serif;

$cardLineHeightFactor: 1.625;

$grayBG: #fcf9ff;
$grayBGBorder: hsl(265, 100%, 97%);
$secondaryLabel: #c5c9e8;
$secondaryDarker: hsl(233,
  33%,
  75%); // Lame to have two; think about your palette...
$keyColor: #9446ed;

// Grid
$largeLayoutMaxWidth: 1440px;
$largeColumnCount: 12;
$largeColumnGutter: 16px;
$largeColumnMargin: 32px;
$largeBodyColumnCount: 7;
// $largeContentWidth: calc(
// 100% - #{$largeColumnGutter} * (#{$largeColumnCount} - 1)
// );
$largeContentWidth: 796px;
$largeColumnSize: calc(#{$largeContentWidth} / #{$largeColumnCount});

$handheldLayoutMaxWidth: 1440px;
$handheldColumnCount: 4;
$handheldColumnGutter: 16px;
$handheldColumnMargin: 16px;
$handheldBodyColumnCount: 4;
$handheldContentWidth: calc(100% - #{$handheldColumnGutter} * (#{$handheldColumnCount} - 1));
$handheldColumnSize: calc(#{$largeContentWidth} / #{$handheldColumnCount});

$break-small: 767px;
$break-large: 1000px;

$shorty-threshold: 767px;

@mixin respond-to($media) {
  @if $media==handhelds {
    @media only screen and (max-width: $break-small) {
      @content;
    }
  }

  @else if $media==shorties {
    @media only screen and (max-height: $shorty-threshold) {
      @content;
    }
  }

  @else if $media==non-shorties {
    @media only screen and (min-height: $shorty-threshold + 1) {
      @content;
    }
  }

  @else if $media==handhelds-or-shorties {

    @media only screen and (max-width: $break-small),
    only screen and (max-height: $shorty-threshold) {
      @content;
    }
  }

  @else if $media==non-handhelds-or-shorties {
    @media only screen and (min-width: $break-small + 1) and (min-height: $shorty-threshold + 1) {
      @content;
    }
  }

  @else if $media==non-handhelds {
    @media only screen and (min-width: $break-small + 1) {
      @content;
    }
  }

  @else if $media==medium-screens {
    @media only screen and (min-width: $break-small + 1) and (max-width: $break-large - 1) {
      @content;
    }
  }

  @else if $media==wide-screens {
    @media only screen and (min-width: $break-large) {
      @content;
    }
  }
}


$gray1: #102a43;
$gray2: #243b53;
$gray3: #334e68;
$gray4: #486581;
$gray5: #627d98;
$gray6: #829ab1;
$gray7: #9fb3c8;
$gray8: #bcccdc;
$gray9: #d9e2ec;
$oldGray10: #f0f4f8;
$oldKey1: #44056e;
$oldKey2: #580a94;
$oldKey3: #690cb0;
$oldKey4: #7a0ecc;
$oldKey5: #8719e0;
$oldKey6: #9446ed;
$oldKey7: #a368fc;
$oldKey8: #b990ff;
$oldKey9: #dac4ff;
$oldKey10: #f2ebfe;

@function sectionWidth($sectionColumnCount, $columnSize, $columnGutter) {
  @return calc(#{$columnSize} * #{$sectionColumnCount} + #{$columnGutter} * (#{$sectionColumnCount} - 1));
}

@function pow($number, $exponent) {
  $value: 1;

  @if $exponent>0 {
    @for $i from 1 through $exponent {
      $value: $value * $number;
    }
  }

  @else if $exponent < 0 {
    @for $i from 1 through -$exponent {
      $value: $value / $number;
    }
  }

  @return $value;
}

@function ms($value, $ms-ratio: $ratio, $ms-base: 1) {
  $size: pow($ms-ratio, $value) * $ms-base;
  @return #{$size + "rem"};
}

@mixin study-font-size($value) {
  font-size: ms($value);

  @include respond-to(handhelds) {
    font-size: ms($value, $ratio, $base / $mobileBase);
  }
}

@mixin margin-top($multiple) {
  margin-top: #{$multiple * $lineHeight};

  @include respond-to(handhelds) {
    margin-top: #{$multiple * $mobileLineHeight};
  }
}

$paragraphSpacing: 22px;

@mixin margin-bottom($multiple) {
  margin-bottom: #{$multiple * $lineHeight};

  @include respond-to(handhelds) {
    margin-bottom: #{$multiple * $mobileLineHeight};
  }
}

@mixin line-height($multiple) {
  line-height: #{$multiple * $lineHeight};

  @include respond-to(handhelds) {
    line-height: #{$multiple * $mobileLineHeight};
  }
}

@mixin pagePadding() {
  @include respond-to(handhelds) {
    padding-left: $handheldColumnMargin;
    padding-right: $handheldColumnMargin;
  }

  @include respond-to(non-handhelds) {
    padding-left: $largeColumnMargin;
    padding-right: $largeColumnMargin;
  }
}

* {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  background: transparent;
  box-sizing: border-box;
}

html {
  font-family: $bodyFont;
  font-size: $base;
  color: $gray2;
  background-color: white;

  @include respond-to(handhelds) {
    font-size: $mobileBase;
  }
}

body {
  @if ($showBaselineGrid) {
    background-image: linear-gradient(to bottom,
        rgba(0, 170, 255, 0.3) 1px,
        transparent 1px);
    background-position: left top;
    background-repeat: repeat;
    background-size: 100% $lineHeight;
  }
}

// Keep our anchors from headbutting the top of the viewport.
$anchorOffset: 64px;

.BodyColumn h1 {
  @include margin-top(2);
  @include margin-bottom(1);
  @include line-height(1.5);
  font-family: $headingFont;
  font-size: ms(2);
  font-weight: 600;
  color: $gray3;
  letter-spacing: -0.02em;
  position: relative;

  &::after {
    position: absolute;
    background-color: $primaryKey;
    width: 6px;
    top: $anchorOffset;
    bottom: 0px;
    border-radius: 3px;
    left: -26px;
    content: '';

    @include respond-to(medium-screens) {
      left: -16px;
      width: 4px;
      border-radius: 2px;
    }

    @include respond-to(handhelds) {
      left: -$handheldColumnMargin + 4px;
      width: 4px;
    }
  }
}


.BodyColumn {
  h2 {
    @include margin-top(2);
    @include margin-bottom(1);
    @include line-height(1);
    font-family: $headingFont;
    font-size: ms(1);
    font-weight: normal;
    border-bottom: 1px solid $key00;
  }

  h1,
  h2 {
    &::before {
      display: block;
      content: " ";
      margin-top: -$anchorOffset;
      height: $anchorOffset;
      visibility: hidden;
      pointer-events: none;
    }
  }
}

h1+h2 {
  @include margin-top(1);
}

p,
li {
  @include margin-top(0);
  margin-bottom: $paragraphSpacing;
  @include line-height(1);
}

li {
  @include margin-bottom(0);
}

ul,
ol {
  margin-bottom: $paragraphSpacing;
  padding-left: 1em;
  list-style-position: outside;

  @include respond-to(handhelds) {
    margin-left: 1em;
  }
}

a {
  text-decoration: none;
  color: $primaryDark;
}

a:hover {
  text-decoration: underline;
}

pre {
  @include margin-bottom(1);
}

#EssayContents {
  counter-reset: MarginNote;
  @include essayBodyColumnLayout;
}

#EssayContentsInner {
  // We want the positioning basis to be exclusive of padding.
  position: relative;
}

.BodyColumn,
.BodyColumn .Footer {
  margin-left: auto;
  margin-right: auto;
  max-width: $desktopBodyColumnWidth;
  position: relative;
}

.BodyColumn {

  @include respond-to(handhelds) {
    margin-left: unset;
    width: unset;
  }

  &>img,
  &>p>img {
    max-width: 100%;
    margin-bottom: $paragraphSpacing;

    @include respond-to(handhelds) {
      width: 100%;
    }

    &.FullBleed {
      max-width: unset;
      display: block;

      margin-left: -$largeColumnMargin;
      margin-bottom: 0;
      width: calc(100% + 2 * #{$largeColumnMargin});

      @include respond-to(handhelds) {
        margin-left: -$handheldColumnMargin;
        width: calc(100% + 2 * #{$handheldColumnMargin});
      }

      &+p {
        @include margin-top(1);

        &.ImageCaption {
          @include margin-top(0);
          @include margin-bottom(0.5);
          @include line-height(1);
          font-size: ms(-1);
          color: $gray5;

          a {
            color: inherit;
            text-decoration: underline;
          }
        }
      }
    }
  }

  img.RetinaImage {
    max-width: 100%;
  }

  img.CropToCenter {
    object-position: center;
    object-fit: contain;
    width: 100%;
    height: max-content;
    max-height: max-content;
  }
}

.BodyColumn blockquote {
  @include margin-top(0);
  @include margin-bottom(2);
  font-size: 17px;
  line-height: 30px;

  margin-left: 32px;
  margin-right: 32px;

  @include respond-to(handhelds) {
    @include margin-bottom(1);
    border-left: 1px solid $gray7;
    margin-left: unset;
    padding-left: 16px;
    margin-right: unset;
    font-size: $mobileBase;
    line-height: $mobileLineHeight;
  }

  &.Flavor {
    font-style: italic;
    font-size: ms(0);
    line-height: $lineHeight;

    color: $gray2;
    @include margin-top(1.5);
    margin-left: 64px;
    margin-right: 64px;

    @include respond-to(handhelds) {
      font-size: inherit;
      line-height: 30px;
      margin-top: unset;
      margin-left: unset;
      margin-right: unset;
    }

    .Attribution {
      font-size: ms(0);
      @include margin-top(0.25);
      font-style: normal;
      text-align: right;
      line-height: 1.4;
    }
  }
}

.katex-display {
  @include respond-to(handhelds) {
    overflow-x: auto;
    overflow-y: visible;
  }
}

.MarginNoteMarker {
  &::after {
    counter-increment: MarginNote;
    content: "["counter(MarginNote) "]";
    vertical-align: super;
    line-height: 0;
    font-size: ms(-1);
    color: $primaryDark;
    cursor: pointer;
  }
}

.MarginNote {
  background-color: white;
  box-shadow: 0 20px 50px rgba(0, 0, 0, 0.12), 0 1px 10px rgba(0, 0, 0, 0.05);
  border-radius: 8px;
  position: absolute;
  font-size: ms(0);
  padding: 16px;
  left: 16px;
  right: 16px;
  @include margin-top(1);
  opacity: 0;
  transition: opacity 0.15s linear;
  z-index: 10;

  @include respond-to(handhelds) {
    left: 0px;
    right: 0px;
    padding: 16px;
  }

  @include respond-to(non-handhelds) {
    pointer-events: none;
  }
}

/* Footer */

.BodyColumn .Footer {
  border-top: 1px solid $grayBGBorder;
  @include margin-top(2);
  @include margin-bottom(2);
  padding-top: $lineHeight*2;

  @include respond-to(handhelds) {
    @include margin-top(2);
    padding-top: $lineHeight;
  }

  h3 {
    font-family: $UIFont;
    font-weight: 600;
    color: $gray2;
    font-size: ms(0);
    line-height: $lineHeight;
  }

  .CitationPreamble {
    margin-bottom: 0;
  }

  blockquote {
    font-family: $UIFont;
    line-height: 1.5;
    margin-bottom: $paragraphSpacing;

    @include respond-to(handhelds) {
      border-left: none;
    }
  }
}

.PatreonButton {
  flex-shrink: 0;
}

/* BELOW HERE INTERACTIVE STYLES */

.newUser,
.curUser {
  transition: opacity 0.4s linear;
}

.curUser {
  animation-delay: 200ms;
}

.LoginWrapper {
  height: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
}

.LoginWrapper .LoginCTA {
  font-family: $UIFont;
  @include study-font-size(0);
  line-height: $base * $cardLineHeightFactor;
  padding: 16px;
  border-bottom: 1px solid $grayBGBorder;
}

.LoginConfirmation {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-family: $UIFont;
  @include study-font-size(0);
  line-height: $base * $cardLineHeightFactor;
  width: 330px;
  flex-grow: 1;
  text-align: center;
  align-self: center;
  padding: 16px;
}

.LoginConfirmationGlyph {
  width: 80px;
  height: 80px;
  mask: url("/assets/ui/correct.svg");
  mask-size: 100%;
  mask-position: center;
  mask-repeat: no-repeat;
  background-color: $primaryDark;
  margin-bottom: 32px;
  flex-shrink: 0;
}

.LoginWrapper {
  .ConsentPreamble {
    position: absolute;
    margin-bottom: 0;
    bottom: 16px;
    width: calc(100% - 16px * 2);
    @include study-font-size(-1);
    line-height: $lineHeight/2;
    color: $gray5;

    a {
      color: $gray7;
    }
  }
}

.curUser {
  display: none;
}

.FreefloatingLoginUpsell {
  position: relative;
  margin-bottom: 0;

  @include respond-to(handhelds-or-shorties) {
    margin-top: $handheldColumnMargin;
    margin-left: -$handheldColumnMargin;
    width: calc(100% + 2 *#{$handheldColumnMargin});
  }

  .CardDynamicsContainer {
    position: relative;
    top: 0px;
  }
}

text {
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  font-family: $UIFont;
}

.StudySummaryContainer {
  position: absolute;
  top: 0;
  font-family: $UIFont;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  max-height: 100vh;


  .PatreonUpsell {
    display: flex;
    align-items: center;

    .PatreonButton {
      margin-left: 24px;
    }

    p {
      @include margin-bottom(0);
    }

    margin-bottom: $paragraphSpacing;


    @include respond-to(handhelds) {
      flex-wrap: wrap;
      justify-content: center;

      .PatreonButton {
        margin-left: 0;
      }

      p {
        margin-bottom: $paragraphSpacing;
      }

      margin-bottom: $paragraphSpacing * 2;
    }
  }



  & .StudySummaryInnerContainer {
    max-width: 700px;
    margin: 0 auto;
    padding-top: $largeColumnMargin*2;
    padding-bottom: $largeColumnMargin*4;

    @include respond-to(handhelds) {
      max-width: unset;
      padding: $handheldColumnMargin*2;
      padding-bottom: $handheldColumnMargin*4;
    }

    @include respond-to(non-handhelds) {
      margin: 0 96px;
    }
  }

  .OldLevelContainer {
    padding: 0;
    background-color: transparent;
    border-top: unset;
    position: relative;
    bottom: unset;
    height: unset;

    animation: fade-in 0.4s linear 2.8s both;

    @include respond-to(non-handhelds) {
      width: 700px;
    }
  }

  .LevelLabel {
    @include respond-to(handhelds) {
      font-weight: 600;
    }
  }

  @include respond-to(non-handhelds) {
    .Level {
      widtH: unset;
      align-items: center;
      flex: 0 0;
      position: relative;
    }

    .LevelLabel {
      font-size: 16px;
      position: absolute;
      white-space: nowrap;
      color: $gray5;
    }

    .LevelImageContainer {
      margin-top: 1rem;
      width: 56px;
      height: 59px;

      .LevelImage {
        width: 56px;
        height: 59px;
        background-size: 100%;
      }
    }

    .StudyLevelAverageInteriorHandheld {
      display: none;
    }
  }

  .MarkingHistogram {
    display: flex;
    width: 100%;
    justify-content: center;
    flex-grow: 0;
    flex-wrap: wrap;
  }

  .MarkingHistogramPoint {
    width: 5px;
    height: 4px;
    margin-left: 2px;
    margin-right: 2px;
    margin-bottom: 4px;
    background-color: $gray7;
    border-radius: 2px;
  }

  .PatreonUpsell {
    animation: fade-in 0.4s linear 2.8s both;
  }

  svg.ProgressGraph {
    overflow: visible;
    margin-top: 12px;

    @include respond-to(handhelds) {
      margin-left: -16px;
      margin-top: 4px;
    }

    animation: fade-in 0.4s linear 2.8s both;
    @include margin-bottom(2);
  }

  .ProgressGraphDot {
    fill: $gray8;
    r: 4;
  }

  .ProgressGraphDotLast {
    fill: $oldKey5;
    r: 6;
  }

  .ProgressGraphLine {
    stroke: $gray8;
    fill: none;
    stroke-width: 1;
  }

  .GoalLine {
    stroke: $oldKey5;
    stroke-width: 1.5;
    stroke-linecap: round;
  }

  .ProjectionLine {
    stroke: $oldKey8;
    stroke-dasharray: 4;
  }

  .ProjectionDot {
    fill: $grayBG;
    stroke: $oldKey5;
    stroke-width: 1.5;
  }

  .EssayLabel {
    font-size: 12px;
    fill: $oldKey5;

    @include respond-to(handhelds) {
      font-size: 10px;
    }
  }

  .LabelConnector {
    stroke: $oldKey5;
    stroke-width: 1;
  }

  .TodayLabel {
    fill: $oldKey5;
    font-size: 12px;

    @include respond-to(handhelds) {
      font-size: 8px;
    }
  }

  .TodayLine {
    stroke: $oldKey7;
    stroke-width: 1;
  }

  .tick text {
    font-size: 12px;
    fill: $gray7;

    @include respond-to(handhelds) {
      font-size: 8px;
    }
  }

  .Gridlines line,
  .y.axis .tick line {
    stroke: $gray9;
    stroke-width: 0.5;
  }

  .GoalIndicator {
    fill: $oldKey5;

    @include respond-to(handhelds) {
      font-size: 10px;
    }
  }

  .GoalDescription {
    fill: $gray7;
    font-size: 12px;

    @include respond-to(handhelds) {
      font-size: 10px;
    }
  }

  .LabelBackground {
    fill: $grayBG;
    opacity: 0.8;
  }

  .StudySummaryContinueReadingUpsellBar {
    position: fixed;
    z-index: 200;
    left: 0;
    right: 0;
    bottom: 0;
    height: 80px;
    background-color: $oldKey10;
    border-top: 1px $oldKey9 solid;
    box-shadow: 0px 0px 30px rgba($oldKey2, 0.07), 0px 0px 10px rgba($oldKey2, 0.07);

    animation: slide-up 0.4s $expoOut 4s both;

    @include respond-to(handhelds) {
      height: unset;
      padding-top: 16px;
      padding-bottom: 16px;
    }

    .StudySummaryContinueReadingUpsellContainer {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      max-width: 700px;
      margin: 0 auto;
      height: 100%;

      @include respond-to(handhelds) {
        max-width: unset;
        padding-left: $handheldColumnMargin;
        padding-right: $handheldColumnMargin;
        flex-direction: column;
      }

      p {
        font-size: 14px;
        font-weight: 400;
        line-height: 1.75;
        color: $oldKey1;
        margin-right: 24px;
        margin-bottom: 0;
        text-align: right;
        text-shadow: 0px 1px 0px $oldKey10;

        @include respond-to(handhelds) {
          font-size: 11px;
          color: $oldKey1;
          line-height: 1.5;
          text-align: center;
          margin-right: 0;
          margin-bottom: 16px;
        }
      }

      button {
        height: 44px;
        border: none;
        border-radius: 22px;
        appearance: none;
        background-color: $oldKey5;
        color: white;
        font-family: $UIFont;
        @include study-font-size(0);
        padding-right: 1.5em;
        padding-left: 1.5em;

        &:hover {
          background-color: $oldKey6;
        }

        &:active {
          background-color: $oldKey4;
        }

        @include respond-to(handhelds) {
          width: 100%;
        }
      }
    }
  }
}

.StudySummaryGlyph {
  width: 64px;
  height: 64px;
  mask: url("/assets/ui/correct.svg");
  mask-size: 100%;
  mask-position: center;
  mask-repeat: no-repeat;
  background-color: $oldKey5;
  flex-shrink: 0;
  flex-grow: 0;
  display: inline-block;
  margin-left: -98px;
  margin-right: 32px;

  animation: scale-up 0.7s $expoOut 1.0s both;

  @include respond-to(handhelds) {
    display: block;
    margin-left: unset;
    margin-right: 24px;
    // margin-bottom: 32px;
    // width: 96px;
    // height: 96px;
    align-self: center;
  }
}

.StudySummaryHeading {
  @include study-font-size(3);
  @include margin-bottom(2);
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  animation: fade-in 0.4s linear 1.0s both;

  @include respond-to(handhelds) {
    @include study-font-size(2);
    font-weight: 600;
    color: $gray3;
    @include margin-bottom(1);
  }
}

.StudySummaryTodayText,
.StudySummaryProgressPreamble {
  @include study-font-size(1);
  line-height: $cardLineHeightFactor;

  @include respond-to(handhelds) {
    font-size: 14px;
  }

  strong {
    font-weight: 600;
    white-space: nowrap;
  }
}

.StudySummaryTodayText {
  @include margin-bottom(2);

  @include respond-to(handhelds) {
    @include margin-bottom(1);
  }

  animation: fade-in 0.4s linear 1.6s both;
}

.StudySummaryProgressPreamble {
  @include margin-bottom(1.0);

  @include respond-to(handhelds) {
    @include margin-bottom(0.5);
  }

  animation: fade-in 0.4s linear 2.2s both;
  font-weight: 600;
}

.StudySummaryText strong {
  color: $oldKey3;
}

@keyframes fade-in {
  from {
    opacity: 0
  }

  to {
    opacity: 1
  }
}

@keyframes scale-up {
  from {
    transform: scale(0.01)
  }

  to {
    transform: scale(1.0)
  }
}

@keyframes slide-up {
  from {
    transform: translateY(100%);
  }

  to {
    transform: translateY(0%);
  }
}
